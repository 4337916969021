:root {
  --blue-950: #003879;
  --blue-900: #313F70;
  --blue-800: #00589C;
  --blue-600: #4BB0FF;
  --blue-300: #4BB0FF;
  --blue-200: #A7D8FF;
  --blue-100: #CAE6FC;

  --grey-900: #2A3743;
  --grey-700: #657380;
  --grey-500: #B7C1CC;

  --primary-100: var(--blue-200);
  --primary-600: var(--blue-600);
  --primary-700: var(--blue-800);
  --primary-700-rgb: var(--blue-800);
  --primary-800: var(--blue-900);
  --primary-900: var(--blue-950);

  --primary-color: var(--blue-600);
  --primary-bg-highlight: var(--blue-100);
  $shade450: #B7C1CC;

  --surface-0: #ffffff;
  --surface-50: #F8F9FA;
  --surface-100: #f5f5f5;
  --surface-200: #eeeeee;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #E9ECEF;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --surface-910: #060606;

  --primary-hover: var(--blue-100);

  --color-red-0: #fff9f9;
  --color-red-50: #ffdbdb;
  --color-red-100: #ffc0c0;
  --color-red-200: #ffaaaa;
  --color-red-300: #ff9393;
  --color-red-400: #ff7575;
  --color-red-500: #ff5c5c;
  --color-red-600: #ff4b4b;
  --color-red-700: #ff3636;
  --color-red-800: #ff1b1b;
  --color-red-900: #ff0000;
  --color-red-950: #ff0000;
  --divider-color: #DEE2E6;
  --scrollbar-color: #bdbdbd;
  --main-surface-color: var(--surface-0);
  --editor-icon-color-hover: var(--blue-800);
  --focus-ring: var(--editor-icon-color-hover);
  --scrollbar-width: 6px;
  --grid-size-8: 75%;

  /* Общие цветовые токены */

  --bg-overlay: var(--black-rgb);
  --bg-disabled-item: var(--grey-300);
  --bg-secondary: var(--grey-300);

  --text-secondary: var(--grey-700);
  --text-primary: var(--grey-900);
  --text-invalid: #DC1919;

  /* Tag background styles */
  --bg-tag-1: var(--blue-100);
  --bg-tag-2: var(--green-340);
  --bg-tag-3: var(--red-100);
  --bg-tag-4: var(--orange-100);
  --bg-tag-5: var(--grey-400);
  --bg-tag-6: var(--purple-100);

  /*  Text/tag */
  --text-tag-1: var(--blue-800);
  --text-tag-2: var(--green-950);
  --text-tag-3: var(--red-900);
  --text-tag-4: var(--orange-900);
  --text-tag-5: var(--blue-900);
  --text-tag-6: var(--purple-900);

}

body {
  background-color: var(--main-surface-color);
  min-height: unset;
}

html {
  font-size: 16px;
}

/*@font-face {
  font-family: Inter;
  src: url('http://localhost:4200/assets/fonts/Inter-Regular.ttf');
}*/
