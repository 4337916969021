body{

}
.d-none{
  display: none !important;
}

.d-none-icon{
  & .p-treenode-content.p-treenode-selectable{
    height: 2rem;
    margin-left: 2rem;
  }
  & button.p-tree-toggler.p-link.p-ripple{
    display: none !important;
  }
}

*::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: var(--scrollbar-width);
}

*::-webkit-scrollbar-thumb {
background-color: var(--scrollbar-color);
border-radius: 3px;
}

.v-hidden {
  visibility: hidden;
}

.media-trick{
  overflow: scroll; max-width: calc(100vw - 300px);
}

@media print {
  .media-trick{
    max-width: unset;
  }
}
