
.card {
  width: 934px;
  background-color: var(--surface-0);
  border-radius: 5px;
  max-width: 934px;
  text-align: justify;
}

.card-2 {
  width: 450px;
  background-color: var(--surface-0);
  border-radius: 5px;
  margin-right: 20px;
}

.card-4 {
  width: 920px;
  background-color: var(--surface-0);
  border-radius: 5px;
  margin-right: 20px;
  text-align: justify;
}

.card-3 {
  width: 294px;
  background-color: var(--surface-0);
  border-radius: 5px;
  margin-right: 20px;
}
