.upload-block {
  width: 200px;
  height: 200px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(227, 227, 227, 0.25);
  border-radius: 15px;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;

  &:hover {
    .image-preview {
      opacity: 1; z-index: 100;
      cursor: pointer;
    }
  }
  .image-preview {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.5);  opacity: 0;
    border-radius: 15px;

    span {
      font-family: Segoe UI;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 17px;
      color: #FFFFFF;
      display: block;
      position: relative;
      top: 45%;
      z-index: 110;
      left: 10%;
      cursor: pointer;
    }

  }

  i {
    position: absolute;
    top: 3%;
  }


  img {
    max-height: 100%;
    max-width: 100%;

    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 15px;
  }

  .block-container {
    width: 175px;
    height: 175px;
    border: 1px solid;
    border-color: #8C8C8C;
    border-radius: 10px;
    margin: 12px;
    background-color: unset;
    display: block;

    .add-file {
      height: 17px;
      margin-top: 26px;
      text-align: center;
      font-family: Segoe UI;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 17px;
      color: #454545;
      margin-bottom: 24px;
    }

    .uploader {
      width: 40px;
      margin: 0 auto;
    }

    .drag-and-drop-loader {
      color: #8C8C8C;
      font-family: Segoe UI;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 17px;
      text-align: center;
      margin-top: 16px;
    }
    &.dashed{
      border: 1px dashed;
    }
  }

  .default-image {
    width: 18px;
    height: 18px;

    &::before {
      position: relative;
      // top: 9px;
      left: 176px;
      color: #FFE600
    }
  }
}

.image-list {
  margin-top: 35px;
  margin-left: 15px;
}

.p-ripple.p-element.upload.p-button.p-component.p-fileupload-choose.p-button-icon-only {
  background: unset;
  border-color: var(--primary-500); // Можно использовать системные переменные, определяемые в :root (когда будет определена дизайн-система просто скопируем для ЦЛИК)
  color: var(--primary-500);
}
