/* You can add global styles to this file, and also import other style files */

@import "./helpers/variables";
@import "./helpers/helpers";
@import "./media/media";
@import "../app/components/dashboard-info-card/dashboard-info-card.component.scss";
@import "~leaflet/dist/leaflet.css";


/* Добавим сюда проектные стили? */

@import "./components/card";
@import "./components/upload";
@import "./components/p-dialog";
@import "./components/image-editor";
